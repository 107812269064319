$(document).ready(function () {

    $('#facetted-list__sorting').on('change', function () {
        location.href = $(this).val();
    });
});

if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1))
{
  $('html').addClass('is-ie');
}

if ( !$( ".is-ie" ).length ) {

  $(document).ready(function(){
    // Add smooth scrolling to all links
    $('a[href*=\\#]:not(#facettedList a[href*=\\#]):not(.pagination__item a[href*=\\#]):not(a[href*=\\#facettedList])').on('click', function(event) {

      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function(){

          // Add hash (#) to URL when done scrolling (default click behavior)
          window.location.hash = hash;
        });
      } // End if
    });
  });

}
