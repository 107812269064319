console.info(NAME, {
  production: PRODUCTION,
  app: VERSION,
  deprecated_framework: Page
});

// Import our new fresh framework
import { Framework } from "auw-framework";

// react components
import Components from "./components";
// load other extension react components
import * as ExternalComponents from "./extensions";

// load the oldskool shit
import { Page } from "old-framework";
// register old handlebar helpers (depricated)
import "./util/helpers";
// load depracted 2017ish extensions
import "./enable-extensions";

// Load app-store (still needs refactoring due to performance)
import "../Styles/main.scss";

/**
 * Called when bootstrapping finsished.
 * @see bootstrap-server.js
 * @see bootstrap.js
 */
export function run() {
  Framework.registerComponents(Components);

  if (Object.keys(ExternalComponents || []).length) {
    Framework.registerComponents(ExternalComponents);
  }

  if (Page) {
    console.warn(
      "This Website includes the old auw-framework. Performance will therefor not be optimal. Just stay calm."
    );
  } else {
    console.info(
      "No old framework, great! Just make sure you have _only_ react components!"
    );
  }
}
