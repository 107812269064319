import _debugger from 'debug'
import _ from 'lodash'
import { getFnName } from './helpers'

_debugger.enable('*auw*')

const console = window.console

function _noop() {

}

_noop.log = _noop;

function isDevMode () {
  return Boolean(document.getElementById('__bs_script__') || (/(localhost:)?(\d+|loc|local|dev|beta\.artundweise\.\w+)$/i).test(window.location.host) || _.includes(window.location.hash, '!debug'))
}

window['__credits'] = () => {
  console.log(`%c(c) 2017 Artundweise GmbH.
${(new Array(25)).join('\u00CF ')}

Coded with sweat, tears and overtime, for your app-a-like Website experience.
Framework created by Max Scopp, //maxscopp.de`, 'font-size: 200%; line-height: 1.25; color: skyblue;')
}

/**
 * Creates a custom console function, but
 * will only log, if we're in dev mode.
 * @param namespace
 * @returns Function
 */
export function customConsole (namespace) {
  return isDevMode() ? _debugger.apply(null, arguments) : _noop
}

export function debug () {
  return customConsole('auw:debug')
}

function _console (fn, usingMethod, logEachArg, args) {
  if (usingMethod === 'table') {
    return console.table.apply(null, args)
  }

  let debug = customConsole(`auw:${getFnName(fn)}`)
  debug.log = console[usingMethod].bind(console)

  return logEachArg ? _.forEach(Array.from(args), (v) => debug(v)) : debug.apply(debug, args)
}

export function info () { return _console(info, 'info', true, arguments) }
export function error () { return _console(error, 'error', true, arguments) }
export function warn () { return _console(warn, 'warn', true, arguments) }
export function log () { return _console(log, 'log', true, arguments) }
export function inspect () { return _console(inspect, 'table', false, arguments) }

export default {
  info, warn, error, log, inspect, debug
}
