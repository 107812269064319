import { makeComponent, info } from "old-framework";
import { throttle } from "throttle-debounce";

import Masonry from "masonry-layout";

class AuwTeaser {
  constructor(node) {
    this.$node = $(node);

    this._defaults = {
      masonry: false,
      masonryOptions: {}
    };

    this.masonryInstance = null;
    console.log("instance", this);
  }

  __PageReady() {
    if (this.options.masonry) {
      this.masonryInstance = new Masonry(
        this._node,
        this.options.masonryOptions
      );
      $(this._node)
        .find("img")
        .on("load", throttle(100, () => this.layout()));
    } else {
      $(this._node)
        .add(this._node.children)
        .css({
          position: "",
          height: "",
          top: "",
          left: ""
        });
    }
  }

  layout() {
    if (this.masonryInstance) {
      return this.masonryInstance.layout();
      info("Re-Layouted Masonry Teaser instance", this._node);
    }
  }
}

export default makeComponent(AuwTeaser, "teaser");
