import _ from 'lodash'
import { inspect } from './console'
import log, {
  warn,
  info,
  error
} from './console'
import {
  getFnName,
  getInstanceName,
  silentPrettyError
} from './helpers'
import { Page, afterInit as afterPageInit } from './index'
import async from 'async'

var registeredComponents = {}
var instantiatedComponents = []

var instanceCounter = 0

/**
 * @param {Node} component The Component-Node to be initialized.
 * @private
 */
function initializeComponentNode (component, done) {
  let compVars = {}
  let compName = component.getAttribute('@component')
  let compInstance = 'Not constructed yet'

  let removing = []

  function getChildVariables (cb, parent) {
    let storage = {}

    _.forEach(parent.children, (child) => {
      if (child && child.tagName === 'VAR') {
        let varName = child.getAttribute('name')
        let varContent = child.innerHTML

        if (varName && varName.length) {
          let ret = varContent
          try {
            ret = new Function(`try { return ${varContent}; } catch(e) {return "${_.escape(varContent)}"}`)()
          } catch (e) {
            ret = varContent
          }

          storage[varName] = ret
          removing.push(child)

          if (child.children && child.children.length) {
            getChildVariables((data) => {
              storage[varName] = data
              cb(storage)
            }, child)
          } else {
            cb(storage)
          }
        }
      }
    })
  }

  getChildVariables((options) => compVars = options, component)

  /* global $ */
  $(removing).remove()

  if (compName && registeredComponents[compName]) {
    try {
      compInstance = new registeredComponents[compName](component, compVars, instanceCounter)
    } catch (e) {
      silentPrettyError(e)
    }

    if (typeof compInstance !== 'string') {
      const setFrameworkProperty = (classVar, actualVar, force = false) => {
        if (typeof compInstance[classVar] !== 'undefined' && compInstance[classVar] !== actualVar && !force) {
          log(compInstance, compInstance[classVar])
          error(`Class property "${classVar}" is not allowed in ${getInstanceName(compInstance)}`)
        } else {
          compInstance[classVar] = actualVar
        }
      }

      let pageReady = compInstance['__PageReady']
      try {
        if (pageReady instanceof Function) {
          afterPageInit(pageReady, compInstance)
        }
      } catch (e) {
        silentPrettyError(e)
      }

      setFrameworkProperty('_node', component)
      setFrameworkProperty('options', _.assign(compInstance['_defaults'] || {}, compVars))
      setFrameworkProperty('_id', instanceCounter)
      setFrameworkProperty('toString', () => {
        return 'Class:' + getInstanceName(compInstance)
      }, true)

      // TODO: Sealing may be bad? Check that.
      /*
            Object.seal(compInstance)
            info(`${compInstance} has been sealed.`)
            */


      initializeUninitializedComponents(component)
    } else {
      warn(`FATAL: Couldn't construct component "${compName}", errors occurred while constructing class.`)
    }

    instanceCounter++
    instantiatedComponents.push([instanceCounter, compInstance])

    let snapshotData = {}
    let classOrFunction = compInstance instanceof Function ? getFnName(compInstance) : getInstanceName(compInstance) || compInstance
    let classOrFunctionIndex = `@${compInstance instanceof Function ? 'function' : 'class'}`

    snapshotData['@instance'] = instanceCounter
    snapshotData['@componentName'] = compName
    snapshotData[classOrFunctionIndex] = classOrFunction
    snapshotData['@options'] = Object.assign({}, compInstance.options)

    let circJsonCache = [];
    let snapshot = document.createComment(JSON.stringify(snapshotData, (key, value) => {
      // prevents circular ref errors
      if (typeof value === 'object' && value !== null) {
        if (circJsonCache.indexOf(value) !== -1) {
          return
        }
        circJsonCache.push(value)
      }
      return value;
    }, 4))
    circJsonCache = null // For better IE garbage collection

    component.parentNode.insertBefore(snapshot, component)
    component.removeAttribute('@component')
  }

  done(compInstance)
  return compInstance
}

/**
 * Converts Functions and Classes to an Component.
 * @param {Function|Class} handler The function or class to be converted
 * @param {String} name The name which the handler corresponds
 */
export function makeComponent (handler, name) {
  if (registeredComponents[name]) {
    throw new Error(`Component "${name}" already registered!`)
  }

  registeredComponents[name] = handler

  if (Page.ready) {
    initializeUninitializedComponents()
  }
}

/**
 * Looks for any uninitialized components.
 * @param {Node} startPoint The Node element to apply the query
 * @private
 */
export function initializeUninitializedComponents (startPoint = document) {
  let nodes = startPoint.querySelectorAll('[\\@component]')
  nodes = _.filter(nodes, (el) => {
    let instanceComment = el.previousSibling || el.previousElementSibling || null

    /* global Comment */
    if (instanceComment instanceof Comment) {
      let parsed = JSON.parse(instanceComment.data)

      // skip existing instances
      if (parsed && parsed['@instance'] > 0) {
        return false
      }
    }

    return true
  })

  let instances = []

  if (nodes.length) {
    async.each(nodes, (el, done) => {
      initializeComponentNode(el, (instance) => {
        instances.push(instance)
        done()
      })
    }, () => {
      async.each(instances, (instance) => {
        let afterInit = instance['__AfterInit']
        try {
          if (afterInit instanceof Function) {
            afterInit.call(instance)
          }
        } catch (e) {
          silentPrettyError(e)
        }
      })
    })
  }
}