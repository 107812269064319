import { afterInit } from "old-framework";

function stopEvent(e) {
  e = e ? e : window.event;
  if (e.stopPropagation) e.stopPropagation();
  if (e.preventDefault) e.preventDefault();
  e.cancelBubble = true;
  e.cancel = true;
  e.returnValue = false;
  return false;
}

const start = "scroll-start";
const end = "scroll-end";

$.fn.extend({
  // param: (boolean) onlyWhenScrollbarVisible
  // If set to true, target container will not intercept mouse wheel
  //     event if it doesn't have its own scrollbar, i.e. if there is too
  //     few content in it. I prefer to use it, because if user don't see
  //     any scrollable content on a page, he don't expect mouse wheel to
  //     stop working somewhere.

  scrollStop: function(onlyWhenScrollbarVisible) {
    return this.each(function() {
      $(this).on("scroll touchmove mousewheel DOMMouseScroll", function(e) {
        if (onlyWhenScrollbarVisible && this.scrollHeight <= this.offsetHeight)
          return;

        const $this = $(this).parent();

        if ($this.hasClass(end) || $this.hasClass(start)) {
          $this.removeClass([start, end].join(" "));
        }

        if (
          !$this.hasClass(end) &&
          this.scrollLeft + this.clientWidth >= this.scrollWidth
        ) {
          $this.addClass(end);
        } else if (!$this.hasClass(start) && this.scrollLeft <= 0) {
          $this.addClass(start);
        }

        e = e.originalEvent;
        var delta = e.wheelDelta ? -e.wheelDelta : e.detail;

        if (e.type === "touchmove") {
          var touch =
            e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
          var elm = $(this).offset();
          var x = touch.pageX - elm.left;
          var y = touch.pageY - elm.top;
          if (!e.wheelDelta || !delta) {
            delta += x;
          }
        }

        var isIE = Math.abs(delta) >= 120;
        var scrollPending = isIE ? delta / 2 : 0;
        if (delta < 0) {
          this.scrollTop = 0;
          this.scrollLeft += delta;
        } else if (delta > 0) {
          this.scrollLeft += delta;
        }
        stopEvent(e);
      });
    });
  }
});

export const SweetScroll = afterInit(() => {
  $("[data-scroll]").scrollStop(false);
  console.info("container scrolling on");
});
