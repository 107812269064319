import $ from 'jquery'
import _ from 'lodash'
import loadTouchEvents from 'jquery-touch-events'
import { initializeUninitializedComponents } from './component-factory'
import { globalizeGlobals } from './global-factory'
import { warn } from './console'
import MutationObserver from 'mutation-observer'
import { EventEmitter2 as EventEmitter } from 'eventemitter2'
import async from 'async'
import Console from './console'

const ieRe = new RegExp(/MSIE ([0-9]{1,}[.0-9]{0,})/g)

export function afterInit (callback, context = null, args = []) {
  if (!(args instanceof Array)) { throw new Error(`Argument "args" expected to be Array, but got ${typeof args}.`) }
  window.Page.messageBroker.emit('internal.toInitStack', [callback, context, args])
}

/**
 * Page Handler
 */
export default class PageInitiator {
  constructor(root) {
    // set traces to infinity
    Error.stackTraceLimit = Infinity

    this.console = Console;


    // do we have a document?
    if (!root.document) { throw new Error('auwFramework requires a window with an document.') }

    this.ready = false
    this.initStack = []
    this.messageBroker = new EventEmitter({
      wildcard: true,
      newListener: false,
      maxListeners: 20
    })

    this.root = root
    this.root['Page'] = this // expose instance to global context

    this.$window = $(this.root)
    this.$doc = $(this.root.document)
    this.$body = $(this.root.body)

    try {
      let ie = ieRe.exec(navigator.userAgent)
      document.documentElement.setAttribute('data-ie', ie && ie.length > 1 ? ie[1] : false)
    } catch (e) {
      warn('Unable to identify if Browser is an IE<11')
    }

    // expose jQuery and load base stuff
    loadTouchEvents($)
    this.root['jQuery'] = this.root['$'] = $

    this.observer = new MutationObserver(this._handleMutation.bind(this))
    this.observer.observe(document, {
      childList: true,
      subtree: true,
      characterData: true
    })

    this._registerEv(window, 'DOMContentLoaded', this.onReady.bind(this), true)

    this.messageBroker.on('internal.toInitStack', (entry) => {
      this.initStack.push(entry)
      if (this.ready)
        this._workInitStack()
    })

    /*
     this._registerEv(window, 'DOMContentLoaded', () => info('window - DOMContentLoaded - capture'), true)
     this._registerEv(document, 'DOMContentLoaded', () => info('document - DOMContentLoaded - capture'), true)
     this._registerEv(document, 'DOMContentLoaded', () => info('document - DOMContentLoaded - bubble'))
     this._registerEv(document, 'DOMContentLoaded', () => info('window - DOMContentLoaded - bubble'))
     */
  }

  _cycle () {
    globalizeGlobals()
    initializeUninitializedComponents()
    this._workInitStack()
  }

  _handleMutation (records, instance) {
    this._cycle()
  }

  /**
   * Goes through each registered calls after the document has been loaded
   */
  _workInitStack () {
    if (this.ready) {
      async.eachOf(this.initStack, (_entry, k, done) => {
        if (_entry) {
          let [func, context, args] = _entry
          if (func instanceof Function) {
            delete this.initStack[k]
            func.apply(context || null, args ? Array.from(args) : [])
          } else {
            warn('Error inside initStack, entry has no function', _entry)
          }
        }

        done()
      }, () => {
        let isEmpty = true
        this.initStack.map((v) =>
          v !== undefined ? isEmpty = false : null)

        if (isEmpty) this.initStack = []
        else this._workInitStack()
      })
    }
  }

  onReady () {
    this.ready = true
    this._workInitStack()
  }

  _registerEv (evContext, evName, evHandler, bubble = false) {
    (evContext).addEventListener(evName, evHandler, !!bubble)
  }
}
