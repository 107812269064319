import { makeComponent, ComponentHelper } from 'old-framework';


const MODE = {
  tab: 'tabs',
  accordion: 'accordion'
};

const classState = {
  active: 'is-active',
  visible: 'is-visible'
};

function fEach(elements, cb) {
    for (var i = 0; i < elements.length; i++) {
        cb(elements[i]);
    }
}


export class AuwAccordionTabs extends ComponentHelper {
  constructor(classNode) {
    super(classNode);

    this.nodeTitles = [];
    this.nodeContents = [];

    this.nodes('title');
    this.nodes('content');
  }

  __AfterInit() {

          fEach(this.nodeTitles, (el, nth) => el.addEventListener('click', this.handleTitleClick.bind(this)));

    $(this._node).on(
        'touch click webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
        this.updateState.bind(this)
    );

    this.closeAll();

  }

  __PageReady() {
    if (this.options.nthActive) {
      this.show(this.options.nthActive - 1);
    }
  }

  updateState() {
    fEach(this.nodeContents, (contentNode, key, _arr) => {
      // check if content exists, because T3...
      if (contentNode && contentNode.children.length) {
        const isVisible = contentNode.classList.contains(classState.visible);

        contentNode.style.height = isVisible ? `${contentNode.children[0].clientHeight}px` : new String();

        // Adjusting the height of the accortabs by 30px
        contentNode.style.height = parseFloat(contentNode.style.height) + 30 + 'px';
      }
    });
  }

  handleTitleClick(ev) {
    const nth = Array.prototype.indexOf.call(this.nodeTitles, ev.currentTarget || ev.target);

    this.options.mode == MODE.tab ? this.show(nth) : this.toggle(nth);
  }

  show(nth) {
    const contentNode = this.nodeContents[nth];

    if (this.options.mode === MODE.tab) {
      this.closeAll(contentNode);
    }

    // check if content exists, because T3...
    if (contentNode && contentNode.children.length) {
      contentNode.classList.add(classState.visible);
      contentNode.style.height = `${contentNode.children[0].clientHeight}px`;
    }

    this.nodeTitles[nth].classList.add(classState.active);
  }

  hide(nth) {
    const contentNode = this.nodeContents[nth];
    contentNode.classList.remove(classState.visible);
    contentNode.style.height = new String();

    this.nodeTitles[nth].classList.remove(classState.active);
  }

  toggle(nth) {
    const targetContent = this.nodeContents[nth];
    const isCurrentlyVisible = targetContent.classList.contains(classState.visible);

    isCurrentlyVisible ? this.hide(nth) : this.show(nth);
  }

  closeAll(untouched = false) {

    for(var i = 0; i < this.nodeContents.length; i++){
      if(this.nodeContents[i] != untouched){
        this.hide(i);
      }
    }
    //this.fEach(this.nodeContents,((el, nth) => el != untouched && this.hide(nth)));
  }

}

export const accortabs = makeComponent(AuwAccordionTabs, 'accortabs');
