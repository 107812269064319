import {
  makeComponent,
  bindTemplate,
  MediaHelper,
  _MEDIA
} from "old-framework";
import template from "./templates/box.hbs";
import mobileFigure from "./templates/mobile-figure.hbs";

const insertFunction = "append";

const contentClass = "contact-box__body";
const activeClass = "is-active";

const animationOptions = {
  duration: 400,
  easing: "ease"
};

const warn = console.warn;

class AuwContactBox {
  constructor(node) {
    this.$root = null;
    this.$mobileIcon = null;

    this.isOpen = false;
    this.isMobile = false;
    this.beforeInitialized = true;
  }

  __AfterInit() {
    this.$root = $(this._node);

    MediaHelper.matches([_MEDIA.phone, _MEDIA.tablet, _MEDIA.desktop], false, true)(
      isMobile => {
        this.isMobile = isMobile;
        this.updateContent();
      }
    )();
  }

  __PageReady() {
    bindTemplate(this, template).then(node => {
      this.$template = $(node.children);
      this.$template.hide();
      document.body.appendChild(node);

      let appendFunc = insertFunction;

      if (!this.$template[appendFunc]) {
        warn(
          `AuwContactBox couldn't find jQuery's insert function named '${appendFunc}', falling back to 'append'.`
        );
        appendFunc = "append";
      }

      this.$root.addClass(contentClass);
      this.$template[appendFunc](this._node);

      this.updateContent();
      this.beforeInitialized = false;
    });

    bindTemplate(this, mobileFigure).then(node => {
      window.global.$mobileHeader.append(node);
    });
  }

  toggleContent(ev) {
    this.isOpen = !this.isOpen;
    this.updateContent();
  }

  updateContent() {
    let contentWidth = () => (this.isOpen ? 0 : this.$root.outerWidth());
    let contentHeight = () => (this.isOpen ? 0 : this.$root.outerHeight());

    //console.log("UPDATE CONTACT", this.beforeInitialized);

    if (this.$template) {
      this.$template.removeClass("hidden").show();
      this.$root.show();

      this.$template[this.isOpen ? "addClass" : "removeClass"](activeClass);
      this.$template.css(
        "top",
        this.isMobile ? window.global.$mobileHeader.height() : ""
      );

      this.$template.velocity("stop").velocity(
        {
          translateX: this.isMobile ? 0 : contentWidth(),
          translateY: this.isMobile ? contentHeight() * -1 : 0
        },
        this.beforeInitialized ? { duration: 0 } : animationOptions
      );
    } else {
      warn("Template not initialized yet");
    }
  }
}

export const contactBox = makeComponent(AuwContactBox, "contactBox");
