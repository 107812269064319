export const UIConfig = {
  // Size of top & bottom bars in pixels,
  // "bottom" parameter can be 'auto' (will calculate height of caption)
  // option applies only when mouse is used,
  // or width of screen is more than 1200px
  //
  // (Also refer to `parseVerticalMargin` event)
  barsSize: { top: 44, bottom: 'auto' },

  // Adds class pswp__ui--idle to pswp__ui element when mouse isn't moving for 4000ms
  timeToIdle: 4000,

  // Same as above, but this timer applies when mouse leaves the window
  timeToIdleOutside: 1000,

  // Delay until loading indicator is displayed
  loadingIndicatorDelay: 1000,

  // Function builds caption markup
  addCaptionHTMLFn: function (item, captionEl, isFake) {
    // item      - slide object
    // captionEl - caption DOM element
    // isFake    - true when content is added to fake caption container
    //             (used to get size of next or previous caption)
    return item.title || ''
  },

  // Buttons/elements
  closeEl: true,
  captionEl: true,
  fullscreenEl: true,
  zoomEl: true,
  shareEl: false,
  counterEl: true,
  arrowEl: true,
  preloaderEl: true,

  // Tap on sliding area should close gallery
  tapToClose: false,

  // Tap should toggle visibility of controls
  tapToToggleControls: true,

  // Mouse click on image should close the gallery,
  // only when image is smaller than size of the viewport
  clickToCloseNonZoomable: true,

  // Element classes click on which should close the PhotoSwipe.
  // In HTML markup, class should always start with "pswp__", e.g.: "pswp__item", "pswp__caption".
  //
  // "pswp__ui--over-close" class will be added to root element of UI when mouse is over one of these elements
  // By default it's used to highlight the close button.
  closeElClasses: ['item', 'caption', 'zoom-wrap', 'ui', 'top-bar'],

  // Separator for "1 of X" counter
  indexIndicatorSep: ' / '
}
