import _ from 'lodash'

var _globals = window['global'] = window['global'] || {
  window: window,
  document: document
}

function initializeGlobalVariable (variable) {
  let varDef = variable.getAttribute('@global')

  let [, , varCaster, varName] = varDef.match(/^((.*):)?([\w\d\s_$]+)$/i)

  if (!varCaster) {
    return (_globals[varName] = variable)
  }

  return (_globals[varName] = new Function(`try { return ${varCaster}; } catch(e) {return false}`).call(variable))
}

export function globalizeGlobals (startPoint = document) {
  return new Promise((resolve, reject) => {
    let globals = Array.from(startPoint.querySelectorAll('[\\@global]'))
    globals.forEach(initializeGlobalVariable)
  })
}
