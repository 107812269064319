var Handlebars = require("/var/lib/jenkins/jobs/www.bvl-seminare.de/workspace/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"mobile-header\" @global=\"$(this):$mobileHeader\">\n  <div class=\"mobile-header__burger\">\n    <a class=\"nolink hamburger hamburger--collapse\" type=\"button\" aria-label=\"Menu\" aria-controls=\"navigation\">\n      <span class=\"hamburger-box\">\n        <span class=\"hamburger-inner\"></span>\n      </span>\n    </a>\n  </div>\n\n  <div class=\"mobile-header__logo\" "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"logo") || (depth0 != null ? lookupProperty(depth0,"logo") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"logo","hash":{},"data":data,"loc":{"start":{"line":10,"column":35},"end":{"line":10,"column":43}}}) : helper)))
    + "></div>\n\n</div>\n";
},"useData":true});