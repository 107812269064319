import { afterInit } from "old-framework";
import _ from "lodash";
import customSelect from "custom-select";

function makeSelects() {
  customSelect("select", {
    containerClass: "select",
    openerClass: "select__opener",
    panelClass: "select__panel",
    optionClass: "select__option",
    optgroupClass: "select__optgroup"
  });
}

export const selects = afterInit(makeSelects);
