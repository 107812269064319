import { assign as merge } from "lodash";
import Page, { makeComponent } from "old-framework";
import Templates from "./templates";

import Slideout from "slideout";
import iOS from "is-ios";

const padding = 270;
const tolerance = 70;
const submenuSelector = "is-submenu";
const activeClass = "is-active";

class AuwMobileMenu {
  constructor(node, _config) {
    let config = merge({});
    let logoId = (this.logoId =
      "auw" + Math.floor(Math.random() * 1e8).toString(36));

    this.isOpen = false;
    this.menuNode = node;
    this.panelNode = document.getElementById("view-box-site");

    this.$header = $(Templates.header({ logo: logoId }));
    this.$header.insertBefore(this.panelNode);
    this.$burger = this.$header.find('[aria-controls="navigation"]');

    let instance = new Slideout({
      panel: this.panelNode,
      menu: this.menuNode,
      side: "left",
      padding: padding,
      tolerance: tolerance
    });

    if (iOS) {
      instance.disableTouch();
    }

    this.api = instance;
    let fixed = this.$header[0];

    instance.on("translate", translated => {
      fixed.style.transform = "translateX(" + translated + "px)";
      this.updateMenu(translated >= tolerance);
    });

    instance.on("beforeopen", () => {
      fixed.style.transition = "transform 300ms ease";
      fixed.style.transform = `translateX(${padding}px)`;

      this.updateHeader();
    });

    instance.on("beforeclose", () => {
      fixed.style.transition = "transform 300ms ease";
      fixed.style.transform = "translateX(0px)";
    });

    instance.on("open", () => {
      fixed.style.transition = "";
      this.updateMenu();
    });

    instance.on("close", () => {
      fixed.style.transition = "";
      this.updateMenu();
    });
  }

  __AfterInit() {
    window.Page.messageBroker.emit(["AuwMobileMenu", "afterInit"], this);
  }

  updateHeader(translated = false) {
    //("update header");
    this.$header.css("transform", translated && `translateX(${translated}px)`);
    $("#view-box-site").css(
      "paddingTop",
      window.innerWidth <= 768 ? this.$header.outerHeight() + "px" : ""
    );
  }

  updateMenu(forced = null) {
    let isOpen = forced || this.api.isOpen();
    let $els = $(this.menuNode).add(this.$header);

    $els[isOpen ? "addClass" : "removeClass"]("is-open");

    this.$burger[isOpen ? "addClass" : "removeClass"]("is-active");
  }

  __PageReady() {
    this.$burger.on("click", () => {
      let isOpen = !this.api.isOpen();
      this.api.toggle();
      this.updateMenu(isOpen);
    });

    this.$header.find(`[${this.logoId}]`).append(window.global.$mobileLogo);

    this.updateHeader();

    $(this.menuNode).css("display", "");
    $(this.menuNode)
      .find("[data-action]")
      .on("click", ev => {
        let $this = $(ev.currentTarget || ev.delegateTarget || ev.target);

        if ($this.attr("href") === "#") {
          ev.preventDefault();
        }

        let $parentItem = $this.parent();
        let $submenu = $parentItem.closest("ul");

        switch ($this.attr("data-action")) {
          case "toggleChilds":
            // open?
            if (
              $parentItem
                .children()
                .filter((iter, el) => $(el).hasClass(submenuSelector)).length
            ) {
              $parentItem.toggleClass(activeClass);
              // set text for back-arrow
              $parentItem
                .find(`> .${submenuSelector} > li > [data-action="back"]`)
                .html(`<span>${$parentItem.children("a").text()}</span>`);
            }
            break;
          case "back":
            if ($submenu.is(`.${submenuSelector}`)) {
              $submenu.parent().removeClass(activeClass);
            }
            break;
        }
      });
  }
}

makeComponent(AuwMobileMenu, "mobileMenu");
