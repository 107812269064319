var Handlebars = require("/var/lib/jenkins/jobs/www.bvl-seminare.de/workspace/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"embed-responsive__close\" on:click=\"toggleActive()\">\n      <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n    </div>\n    <div class=\"embed-responsive__frame\">\n      <iframe src=\""
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"fullProviderUrl") : stack1), depth0)) != null ? stack1 : "")
    + "\" frameborder=\"0\" allowfullscreen></iframe>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"embed-responsive embed-responsive-16by9\">\n  <div class=\"embed-responsive__thumbnail\">\n    <div class=\"progressive\">\n      <img class=\"preview lazy\" src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"preview") || (depth0 != null ? lookupProperty(depth0,"preview") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"preview","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":52}}}) : helper))) != null ? stack1 : "")
    + "\" data-src=\""
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"thumbnail") || (depth0 != null ? lookupProperty(depth0,"thumbnail") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"thumbnail","hash":{},"data":data,"loc":{"start":{"line":4,"column":64},"end":{"line":4,"column":81}}}) : helper))) != null ? stack1 : "")
    + "\" alt=\"Video Thumbnail\">\n    </div>\n    <div class=\"embed-responsive__play\" on:click=\"toggleActive()\"></div>\n  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":2},"end":{"line":16,"column":9}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"embed-responsive__caption\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"caption") : stack1), depth0))
    + "</div>\n";
},"useData":true});