import { ComponentHelper, makeComponent } from "old-framework";
import { Progressive } from "./progressive";

import responsiveEmbed from "./templates/embed";

const BASE_URI = {
  youtube: "www.youtube-nocookie.com",
  vimeo: "player.vimeo.com"
};

class AuwVideo extends ComponentHelper {
  constructor(node) {
    super(node);

    this._defaults = {
      showControls: true,
      autoplay: false,
      loop: false
    };

    this.isActive = false;
    this.preview = "";
    this.thumbnail = "";
    this.$thumb = null;
  }

  get query() {
    switch (this.options.provider) {
      case "youtube":
        return (
          "?rel=0&showinfo=0&autoplay=1&color=white&autohide=0" +
          `&controls=${this.options.showControls ? 2 : 0}` +
          `&loop=${this.options.loop ? 1 : 0}`
        );
      case "vimeo":
        "?#noVimeoQuery";
      default:
        return "?#noProvider";
    }
  }

  __AfterInit() {
    if (!this.options.videoId || !this.options.provider) {
      throw new Error(
        "AuwVideo requires `videoId` and `provider`, where provider must be youtube or vimeo."
      );
    }

    this.bindTemplate(responsiveEmbed).then(node => {
      this._node.appendChild(node);
      const nodeId = "_" + Math.round(Math.random() * 1e16).toString(36);
      node.id = nodeId;

      this._progressive = new Progressive({
        el: `#${nodeId}`,
        lazyClass: "lazy",
        removePreview: true
        // scale: true
      }).fire();

      this.options.fullProviderUrl = this.getProviderUrl();
      this.getProviderThumbnail((thumb, med, hires) => {
        this.thumbnail = hires || med;
        this.preview = thumb;
      });

      // start autoplay, if set
      if (this.options.autoplay) this.isActive = true;
    });
  }

  toggleActive() {
    this.isActive = !this.isActive;
  }

  getProviderUrl() {
    let url = `${window.location.protocol}//`;
    let videoId = this.options.videoId;

    switch (this.options.provider) {
      case "youtube":
        return (url += `${BASE_URI.youtube}/embed/${videoId}${this.query}`);
      case "vimeo":
        return (url += `${BASE_URI.vimeo}/video/${videoId}${this.query}`);
      default:
        warn(`Invalid Provider "${this.provider}"`);
        return null;
    }
  }

  getProviderThumbnail(cb) {
    let videoId = this.options.videoId;
    const protocol = (() => {
      try {
        return window.location.protocol;
      } catch (e) {
        return "http:";
      }
    })();

    switch (this.options.provider) {
      case "youtube":
        return cb(
          `${protocol}//img.youtube.com/vi/${videoId}/0.jpg`,
          `${protocol}//img.youtube.com/vi/${videoId}/sddefault.jpg`,
          `${protocol}//img.youtube.com/vi/${videoId}/maxresdefault.jpg`
        );
      case "vimeo":
        return jQuery.getJSON(
          `${protocol}//vimeo.com/api/v2/video/${videoId}.json`,
          data => {
            cb(
              data[0].thumbnail_small,
              data[0].thumbnail_medium,
              data[0].thumbnail_large
            );
          }
        );
      default:
        warn(`Invalid Provider "${this.options.provider}"`);
        return cb(null);
    }
  }
}

export const auwVideo = makeComponent(AuwVideo, "video");
