/*!
 * @license auw-framework Copyright 2017 by Artundweise GmbH (http://artundweise.de)
                       _                   _              _
                      | |                 | |            (_)
             __ _ _ __| |_ _   _ _ __   __| |_      _____ _ ___  ___
            / _` | '__| __| | | | '_ \ / _` \ \ /\ / / _ \ / __|/ _ \,
           | (_| | |  | |_| |_| | | | | (_| |\ V  V /  __/ \__ \  __/
           \__,_|_|   \__|\__,_|_| |_|\__,_| \_/\_/ \___|_|___/\___|
                     Kreativagentur für die digitale Zeit


 ┌────────────────────────────────────────────────────────────────────────────┐
 │ Copyright (C) 2017 artundweise GmbH                                        │
 │                                                                            │
 │ Artundweise GmbH uses third-party libraries or other resources that may    │
 │ be distributed under different licensees.                                  │
 │                                                                            │
 │ In the event that we accidentally failed to contain a required notice,     │
 │ please bring it to our attention through any of the ways detailed here:    │
 │                                                                            │
 │ >> artundweise.de/kontakt                                                  │
 │ >> we@artundweise.de                                                       │
 └────────────────────────────────────────────────────────────────────────────┘
 */

import "what-input";
import PageInitiator from "./page-init";
import { warn } from "./console";

export * from "./component-factory";
export * from "./component-helper";
export * from "./page-init";
export * from "./console";
export * from "./bind-template";
export * from "./media-helper";

function runOrMap() {
  const _root = typeof window !== "undefined" ? window : this;

  if (_root["Page"]) {
    warn("An PageInitiator instance is already running, remapping instance.");
    return _root["Page"];
  }

  return new PageInitiator(window);
}

export const instance = runOrMap();
export const Page = runOrMap();
