function init() {
  var $selectButtons = $("button.select-date");
  $selectButtons.on("click", handleSelectButtonClick);

  $(".certificate-course__register").prop("disabled", true);
}

const valid = "is-valid";
const invalid = "is-invalid";

function handleSelectButtonClick(event) {
  var $button = $(event.target);

  deselectOtherButtons($button);
  setHiddenField($button);
  setCheckmarks($button);
  setSelectedNumbers();
  validateRegistration();
  toggleButtonState($button);
}

function deselectOtherButtons($button) {
  var course = $button.data("course");
  var selector = "button.select-date[data-course='" + course + "']";
  var $otherButtonsForCourse = $(selector).not($button);
  $otherButtonsForCourse.each(function() {
    if (isSelected($(this))) {
      toggleButtonState($(this));
    }
  });
}

function setHiddenField($button) {
  var course = $button.data("course");
  var selector = "input[data-course='" + course + "']";
  var $hiddenField = $(selector);

  if (isSelected($button)) {
    $hiddenField.val("");
  } else {
    var date = $button.data("date");
    $hiddenField.val(date);
  }
}

function toggleButtonState($button) {
  if (isSelected($button)) {
    $button.html($button.data("text-unselected"));
  } else {
    $button.html($button.data("text-selected"));
  }

  $button.toggleClass("selected");
}

function isSelected($button) {
  return $button.hasClass("selected");
}

// Set selected numbers
function setSelectedNumbers() {
  var compulsoryModulesCount = getModulesCount(true).selected;
  var modulesCount = getModulesCount(false).selected;

  $("#compulsory-count").html(compulsoryModulesCount);
  $("#modules-count").html(modulesCount);
}

// Get modules count
function getModulesCount(compulsory) {
  var selector = compulsory
    ? 'input[data-course][data-compulsory="1"]'
    : 'input[data-course][data-compulsory="0"]';
  var $modules = $(selector);

  var selectedModulesCount = 0;
  $modules.each(function() {
    if ($(this).val() != "") {
      selectedModulesCount++;
    }
  });

  return { selected: selectedModulesCount, total: $modules.length };
}

// set checkmarks on courses
function setCheckmarks($button) {
  $button
    .parents(".accortabs")
    .find(".accortabs__title.is-selected")
    .removeClass("is-selected");

  if (isSelected($button)) {
    $button
      .parents(".accortabs__section")
      .prev(".accortabs__title")
      .removeClass("is-selected");
  } else {
    $button
      .parents(".accortabs__section")
      .prev(".accortabs__title")
      .addClass("is-selected");
  }
}

// validate registration
function validateRegistration() {
  var compulsoryModulesCount = getModulesCount(true);
  var modulesCount = getModulesCount(false);

  var compulsoryModulesValid =
    compulsoryModulesCount.selected == compulsoryModulesCount.total
      ? true
      : false;
  var $selectedCompulsoryModules = $(".selected-compulsory-modules");

  var modulesRequired = $("#registration-form").data("required-modules");
  var modulesValid = modulesCount.selected >= modulesRequired;
  var $selectedModules = $(".selected-modules");

  if (compulsoryModulesValid) {
    if (!$selectedCompulsoryModules.hasClass(valid)) {
      $selectedCompulsoryModules.addClass(valid);
    }
  } else {
    $selectedCompulsoryModules.removeClass(valid);
  }

  if (modulesValid) {
    $selectedModules.removeClass(invalid);

    if (!$selectedModules.hasClass(valid)) {
      $selectedModules.addClass(valid);
    }
  } else {
    $selectedModules.removeClass(valid);
  }

  if (compulsoryModulesValid && modulesValid) {
    $(".registration").addClass("registration__valid");
    $(".certificate-course__register").prop("disabled", false);
  } else {
    $(".registration").removeClass("registration__valid");
    $(".certificate-course__register").prop("disabled", true);
  }
}

$(document).ready(function() {
  // initialize certificate course handling
  init();

  // hide accordion with price-information, if no links to price-information are present
  if ($('#c2452').length) {
    if (!$('.certificate-course__price-information').length && !$('.workshop__price-information').length) {
      $('#c2452').parent('.auw-ce').hide();
    }
  }

});


$(document).ready(function() {
  // if (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1))
  // {
  //   console.log('IE!')
  //   if($('#course-dates').length) {
  //     console.log('COURSE DATES!!')
  //     $('.accortans__title').addClass('is-active');
  //     $('.accortabs__section').addClass('is-visible');
  //   }
  // }
})

