/**
 * Register you modules here.
 * Will be immediately loaded after the main file booted the Framework.
 */
export * from './modules/snippets'
export * from './modules/powermail'
export * from './modules/theme-color'
export * from './modules/lazy-loading'
export * from './modules/select'
export * from './modules/file'
export * from './modules/table'
export * from './modules/googleanalytics'
export * from './modules/inner-container-scroll'
