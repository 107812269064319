import { afterInit } from "old-framework";
import _ from "lodash";
import "jquery-unveil";

const wrapperTemplate = '<div class="lazyImage" />';
const isLazyClass = "lazyImage__image";
const isBroken = "lazyImage__image--broken";
const isLoaded = "lazyImage__image--loaded animated fadeIn";

export class AuwLazyContent {
  constructor(nodes) {
    let context = this;
    let $images =
      nodes && $(nodes).length ? $(nodes) : $(".ce-textpic.ce-intext img");

    $images.each((iter, el) =>
      $(el)
        .attr("data-src", el.src)
        .attr("src", "")
    );
    $images.unveil(200, function() {
      $(this)
        .on("load", context.handleLoaded.bind(context, this, true))
        .on("error", context.handleLoaded.bind(context, this, false));
    });

    this.images = $images.toArray();
    _.forEach(this.images, this.handleImage.bind(this));
  }

  handleImage(imageNode) {
    let width = imageNode.getAttribute("width");
    let height = imageNode.getAttribute("height");

    let $image = $(imageNode);
    let $sizer = $(
      `<img class="lazyImage__sizer" src="data:image/svg+xml,<svg version='1.1' width='${width}' height='${height}' xmlns='http://www.w3.org/2000/svg'></svg>" />`
    );
    let $wrap = $image.wrap(wrapperTemplate).parent();

    $wrap.prepend($sizer);

    $sizer.attr("width", width).attr("height", height);

    $(imageNode)
      .data("$sizer", $sizer)
      .addClass(isLazyClass);
  }

  handleLoaded(imageNode, success) {
    let $this = $(imageNode);

    let $sizer = $this.data("$sizer");
    if ($sizer && $sizer.length) {
      $sizer[success ? "removeClass" : "addClass"](isBroken);
    }

    if (success) {
      $this.addClass(isLoaded).removeClass(isBroken);
    } else {
      $this.addClass(isBroken).removeClass(isLoaded);
    }
  }
}

export const lazyLoading = afterInit(lazyLoadMainContents);

function lazyLoadMainContents() {
  return new AuwLazyContent();
}
