import Watcher from 'watchjs'
import html2hscript from 'html2hscript'
import h from 'virtual-dom/h'
import diff from 'virtual-dom/diff'
import patch from 'virtual-dom/patch'
import createElement from 'virtual-dom/create-element'
import async from 'async'
import {
  DOMEvents
} from './dom-events'
import {
  warn
} from './console'

export function bindTemplate(instance, template, watchProp) {
  return new Promise((resolve, reject) => {
    if (!(template instanceof Function)) {
      reject(new InternalError('Given Template is not a Template'))
    }

    if (watchProp) {
      warn('Notice: bindTemplate()\'s argument "watchProp" is deprecated and is no longer used. Template data will always be relative to instance.')
    }

    let tree, newTree, rootNode

    function handleEv(event) {
      if (event && event.type && event.target && event.target.attributes) {
        let target = event.target;
        let funcBody = event.target.getAttribute(`on:${event.type}`)

        if (funcBody) {
          let singleFunc = funcBody.match(/^(\s+)?(\w+)\(\)/i)
          singleFunc = singleFunc && singleFunc.length > 1 ? singleFunc[2] : null;
          if (singleFunc && instance[singleFunc] instanceof Function) {
            instance[singleFunc].call(instance, event)
          } else {
            (new Function('instance', 'ev', 'field', funcBody)).call(instance, instance, event, target)
          }
        }
      }
    }

    function render() {
      return new Promise((resolve) => {
        html2hscript(template(watchProp ? instance[watchProp] : instance), (err, hscript) => {
          if (err) reject(err)
          resolve(new Function('h', `return h("div", [${hscript}])`)(h))
        })
      })
    }

    function diffView() {
      Watcher.noMore = true
      render().then((_tree) => {
        newTree = _tree
        let patches = diff(tree, newTree)
        rootNode = patch(rootNode, patches)
        tree = newTree
      })
    }

    render().then((_tree) => {
      tree = _tree
      rootNode = createElement(tree)

      async.each(DOMEvents, (sect) => {
        let sectEvents = sect.split(/\s+/)
        async.each(sectEvents, (ev) => {
          rootNode.addEventListener(ev, handleEv)
        })
      })

      Watcher.watch(watchProp ? instance[watchProp] : instance, diffView, 0, false)
      resolve(rootNode)
    })
  })
}