import mq from 'mediaquery'
import each from 'async/each'
import 'matchmedia-polyfill'
import 'matchmedia-polyfill/matchMedia.addListener'

import { warn } from './console'

export const _MEDIA = mq.asObject({
  phone: 480,
  phoneLandscape: '(max-device-width : 480px) and (orientation : landscape)',
  tablet: 768,
  tabletLandscape: '(max-device-width : 1024px) and (orientation : landscape)',
  desktop: 992,
  desktopLarge: 1200,
  landscape: '(orientation: landscape)',
  portrait: '(orientation: portrait)',
  retina: '(min-resolution: 192dpi)'
})

function callUser (user, ev) {
  return user(ev.matches, ev)
}

export class MediaHelper {
  /**
   * Checks if a single vanilla media-query matches the current doc.
   * @private
   * @param {*} query 
   */
  static _matchesSingleQuery (query) {
    return window.matchMedia(query)
  }

  /**
   * Will try to match any given media query.
   * Look for the `_MEDIA`
   * @param {*} media 
   * @param {*} callOnlyWhenTrue if true, callback is only called when query is true 
   * @param {*} callWhenChanged if true, an event-listener will be added and `cb` will be called whenever it's changed (except `callOnlyWhenTrue` is true)
   */
  static matches (media, callOnlyWhenTrue = false, callWhenChanged = false) {
    callOnlyWhenTrue = Boolean(callOnlyWhenTrue)
    callWhenChanged = Boolean(callWhenChanged)

    var parent = this
    var fullQuery = String(media)
    var registrants = []

    // build query if multiple
    if (media instanceof Array) {
      fullQuery = ''
      media.forEach((oneQuery, iter) => {
        if (typeof oneQuery === 'string' && oneQuery.length) {
          fullQuery += (iter > 0 ? ', ' : '') + oneQuery
        } else {
          warn('A media-query is invalid and has been excluded from the query chain build.', {
            gotMedia: media,
            erroredAt: oneQuery,
            arrayPosition: iter
          })
        }
      })
    }

    // TODO: Add media-query validation

    // inform registrants, called when `callWhenChanged` is true
    const informRegistrants = (ev) => {
      each(registrants, (registrant) => callUser(registrant, ev))
    }

    // make a internal single instance for change listener (+performance)
    let listenerInstance = false
    if (callWhenChanged) {
      listenerInstance = this._matchesSingleQuery(fullQuery)
      listenerInstance.addListener((ev) => informRegistrants(ev))
    }

    // return register func
    return function (cb) {
      if (callWhenChanged) {
        // push to internal `onChange` stack
        registrants.push(cb)
      }

      // wrapper function for multi-check
      const checkNow = () => {
        let ret = parent._matchesSingleQuery(fullQuery)
        if (callOnlyWhenTrue && ret.matches || !callOnlyWhenTrue) {
          callUser(cb, ret)
        }
      }

      // return anonymous callee
      return checkNow
    }
  }
}