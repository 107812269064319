export const REQUEST = {
  mvc: {
    vendor: 'Auw',
    extensionName: 'AuwSolr',
    pluginName: 'Search',
    controller: 'Search',
    action: 'ajax'
  },
  arguments: {}
}
