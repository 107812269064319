var Handlebars = require("/var/lib/jenkins/jobs/www.bvl-seminare.de/workspace/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "is-expanded";
},"3":function(container,depth0,helpers,partials,data) {
    return "is-active";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <header class=\"searchbox__query-preview-header\">\n          <i class=\"fa fa-refresh fa-spin fa-fw\"></i> Showing results for \""
    + container.escapeExpression((lookupProperty(helpers,"or")||(depth0 && lookupProperty(depth0,"or"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"queryOfResult") : depth0),(depth0 != null ? lookupProperty(depth0,"queryContent") : depth0),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":17,"column":75},"end":{"line":17,"column":108}}}))
    + "\"\n        </header>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"searchbox__query-preview-container\" data-results>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ajaxResult") || (depth0 != null ? lookupProperty(depth0,"ajaxResult") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ajaxResult","hash":{},"data":data,"loc":{"start":{"line":22,"column":69},"end":{"line":22,"column":85}}}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"searchbox searchbox--"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"mode") : stack1), depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isExpanded") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":50},"end":{"line":1,"column":86}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"searchbox__field\">\n    <div class=\"searchbox__field-shadow\">\n      <input autocomplete=\"off\" name=\"tx_auwsolr_facettedlist[q]\" required=\"required\" id=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":4,"column":90},"end":{"line":4,"column":96}}}) : helper)))
    + "\" type=\"text\" value=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"queryContent") || (depth0 != null ? lookupProperty(depth0,"queryContent") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"queryContent","hash":{},"data":data,"loc":{"start":{"line":4,"column":117},"end":{"line":4,"column":133}}}) : helper)))
    + "\" on:input=\"this.query = field.value\" on:keydown=\"handleKeypress()\" placeholder=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"inlinePlaceholder") : stack1), depth0))
    + "\"/>\n      <label for=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":24}}}) : helper)))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"queryPlaceholder") : stack1), depth0))
    + "</label>\n    </div>\n\n    <button alt=\"Search\" class=\"searchbox__close-button fa fa-times\" type=\"button\" on:click=\"onClear()\">close</button>\n    <button class=\"searchbox__send-button fa fa-search\" type=\"submit\" on:click=\"onSubmit()\">search</button>\n  </div>\n\n  <div class=\"searchbox__query-preview "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(lookupProperty(helpers,"and")||(depth0 && lookupProperty(depth0,"and"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"queryContent") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),(depth0 != null ? lookupProperty(depth0,"isExpanded") : depth0),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":12,"column":45},"end":{"line":12,"column":81}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":39},"end":{"line":12,"column":99}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"searchbox__query-preview-sizer\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"activeRequests") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":6},"end":{"line":19,"column":13}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"ajaxResult") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":23,"column":13}}})) != null ? stack1 : "")
    + "\n    </div>\n  </div>\n</div>\n";
},"useData":true});