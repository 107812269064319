module.exports = {}
module.exports.googleOptOut = (function () {
  'use strict'

  const Cookie = require('js-cookie')

  // Disable tracking if the opt-out cookie exists.
  let disableStr = null,
    $gaLink

  let expires = new Date('December 31, 2099 23:59:59')

  // Opt-out function
  function gaOptout () {
    Cookie.set(disableStr, true, { expires: expires, path: '/' })
    window[disableStr] = true

    $gaLink.text($gaLink.data('disabled'))
  }
  // Opt-In function
  function gaOptin () {
    Cookie.remove(disableStr)
    window[disableStr] = false

    $gaLink.text($gaLink.data('enabled'))
  }

  function gaSetState (optState = Cookie.get(disableStr)) {
    return optState ? gaOptout() : gaOptin()
  }

  function init () {
    // prüfen ob cookie gesetzt und dann Text in Link kopieren
    $gaLink = $('.ga-opt-out')
    disableStr = 'ga-disable-' + window.gaProperty
    gaSetState()
    // clickhandler
    $gaLink.click(function (event) {
      let $this = $(event.target)
      gaSetState(!Cookie.get(disableStr))
      return false // prevent #-Anchor
    })
  }

  $(document).ready(init)
})()

// <a href="#" class="ga-opt-out" data-disabled="Sie werden nicht getracked" data-enabled="Tracking ausschalten"></a>
