import { InternalError } from "./exceptions";
import _ from "lodash";
import { getInstanceName } from "./helpers";
import { warn } from "./console";

import { bindTemplate } from "./bind-template";

export class ComponentHelper {
  constructor(node) {
    this._node = node;
  }

  _removeNodeTag(currentNode) {
    return currentNode.removeAttribute("@node");
  }

  /**
   * Assigns a Node, or NodeList to the current instance with
   * the given `name`.
   * @private
   */
  _setClassNodeProperty(name, node) {
    /* global Node, NodeList */
    if (!(node instanceof Node || node instanceof NodeList)) {
      throw new Error(
        `Cannot set class-node property for ${getInstanceName(
          this
        )}, not a Node`
      );
    }

    if (node instanceof NodeList) {
      Array.from(node).forEach(this._removeNodeTag);
    } else {
      this._removeNodeTag(node);
    }

    this[`node${_.upperFirst(_.camelCase(name))}`] = node;
    return node;
  }

  /**
   * Returns all matching `@node`
   * elements and appends and "s" after `nodeName`.
   * Syntax:
   *
   *      `"node" + upperFirst(camelCase(<nodeName>)) + 's'`
   *
   * @see _node
   * @param nodeName
   */
  nodes(nodeName) {
    const _nodes = this._node.querySelectorAll(`[\\@node="${nodeName}"]`);

    if (!_nodes) {
      throw new Error(`Cannot find @node named "${nodeName}" (multiple)`);
    }

    this._setClassNodeProperty(nodeName + "s", _nodes);
  }

  /**
   * Returns a `@node` element and convert the
   * `nodeName` to a class-bound variable,
   * following the syntax like so:
   *
   *    `"node" + upperFirst(camelCase(<nodeName>))`
   *
   * @see nodes
   * @param nodeName
   */
  node(nodeName) {
    const _node = this._node.querySelector(`[\\@node="${nodeName}"]`);

    if (!_node) {
      throw new Error(`Cannot find @node named "${nodeName}"`);
    }

    this._setClassNodeProperty(nodeName, _node);
  }

  bindTemplate(template, watchProp) {
    return bindTemplate(this, template, watchProp);
  }
}
