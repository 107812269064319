var Handlebars = require("/var/lib/jenkins/jobs/www.bvl-seminare.de/workspace/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "is-active";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <header class=\"searchbox__query-preview-header\">\n        <i class=\"fa fa-refresh fa-spin fa-fw\"></i>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"searchbox__query-preview-container\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"ajaxResult") || (depth0 != null ? lookupProperty(depth0,"ajaxResult") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"ajaxResult","hash":{},"data":data,"loc":{"start":{"line":12,"column":54},"end":{"line":12,"column":70}}}) : helper))) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"searchbox__query-preview "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"and")||(depth0 && lookupProperty(depth0,"and"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"queryContent") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),(depth0 != null ? lookupProperty(depth0,"isExpanded") : depth0),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":79}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":97}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"searchbox__query-preview-sizer\">\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"and")||(depth0 && lookupProperty(depth0,"and"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"queryContent") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),(depth0 != null ? lookupProperty(depth0,"activeRequests") : depth0),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":50}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "    Showing results for \""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"queryOfResult") || (depth0 != null ? lookupProperty(depth0,"queryOfResult") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"queryOfResult","hash":{},"data":data,"loc":{"start":{"line":8,"column":25},"end":{"line":8,"column":42}}}) : helper)))
    + "\"\n    </header>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ajaxResult") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"useData":true});