import { afterInit } from "old-framework";
import uuidV4 from "uuid/v4";

const selector = "label > input";

function powermail2Styleable() {
  $(selector).each((iter, el) => {
    let $input = $(el);
    let $label = $(el.parentNode);

    // $label is <label>? AND $input type checkbox or radio?
    if (
      $label.is("label") &&
      ["radio", "hidden", "checkbox"].indexOf($input.prop("type")) >= 0
    ) {
      //console.log($input);

      // IMPORTANT: The Hidden field **requires** to be **before** the first
      // input[type="checkbox"] due to PHP's queryParams override.
      if ($input.prop("type") === "hidden") {
        $input.insertBefore($input.parent());
      }

      let forId = $input.attr("id") || uuidV4();

      $input.prop("id", forId);
      $label.prop("for", forId);

      $input.insertBefore($label);
    }
  });
}

export const powermail = afterInit(powermail2Styleable);
