import { afterInit } from "old-framework";
import OneColor from "onecolor";

afterInit(() => {
  try {
    let colorHex = OneColor(global.theme).hex();

    $(`<meta name="theme-color" content="${colorHex}">`).appendTo(
      document.head
    );
  } catch (e) {
    console.info("Unable to set ThemeColor for Client, ignoring...");
  }
});
