import uuid from "uuid/v4";
import _ from "lodash";
import kc from "key-code";

import Page, {
  makeComponent,
  ComponentHelper,
  bindTemplate,
  error,
  warn,
  info,
  MediaHelper,
  _MEDIA
} from "old-framework";

import { REQUEST } from "./request";

import { throttle } from "throttle-debounce";

import BaseTemplate from "./templates/base.hbs";
import MobileMenuTemplate from "./templates/mobile.hbs";

const activeEntry = "activeEntry";
const isFocused = "is-focused";
const href = "[href],[data-href]";

const MODE = {
  STATIC: "static",
  EXPANDING: "expanding"
};

const focus = {
  NEXT: 1,
  PREVIOUS: -1
};

var mobileMenuInstance = false;
var isMobileApplied = false;

class AuwSolrSearch extends ComponentHelper {
  /**
   * Construction method
   * @param {*} node
   */
  constructor(node) {
    super(node);
    // set defaults
    this._defaults = {
      mode: "static",
      sequentialDelay: 400,
      minLength: 2
    };

    this.node("queryInput");

    this.id = uuid();
    this.$root = $(node.children);

    // define vars
    this.queryContent = null;
    this.ajaxResult = null;
    this.queryOfResult = null;
    this.activeRequests = 0;
    this.isExpanded = false;
    this.isPhone = false;

    MediaHelper.matches(_MEDIA.phone, false, true)(
      isPhone => (this.isPhone = isPhone)
    )();

    window.Page.messageBroker.on(
      ["AuwMobileMenu", "afterInit"],
      mmenuInstance => {
        mobileMenuInstance = mmenuInstance;
      }
    );
  }

  /**
   * Validate myself and constructing
   */
  __AfterInit() {
    if (!this.options.results) {
      throw new Error(
        'AuwSolrSearch requires option "results", but got ' +
          this.options.results
      );
    }

    if (this.options.mode === MODE.STATIC) {
      this.isExpanded = true;
    }

    if (_.findKey(MODE, this.options.mode) < 0) {
      throw new Error(
        'AuwSolrSearch requires option "mode", but got invalid value ' +
          this.options.mode
      );
    }

    // make function links
    this.handleQueryChangeThrottled = throttle(
      this.options.sequentialDelay,
      this.handleQueryChange
    );

    // bind templates
    this.bindTemplate(BaseTemplate).then(rootNode => {
      let $hidden = this.$root.find('input[type="hidden"]');
      this.$root.html(rootNode);
      this.$root.append($hidden);
    });

    $("html,body").on("click", ev => {
      if (!$.contains(this.$root.get(0), ev.target) && this.isExpanded) {
        this.unfocus();
      }
    });
  }

  /**
   * Magic function called when page is ready
   */
  __PageReady() {
    //console.log(this.options);

    if (this.options.mode === MODE.EXPANDING) {
      if (this._node.offsetTop) {
        this.$root.find("[data-need-offset]").css("top", this._node.offsetTop);
      }
    }
  }

  /**
   * Handles the query input change and calls an request, if appropriate
   * @see query The setter calls this function, but throttled
   */
  handleQueryChange() {
    if (
      this.queryContent.length >= this.options.minLength &&
      this.queryOfResult !== this.query
    ) {
      console.log("I might want to send an ajax request now");
      this.sendRequest();
    } else {
      console.info("Search: Updated query, but wont send request now.");
    }

    if (!this.queryContent.length) {
      this.ajaxResult = null;
      this.queryOfResult = null;
    }
  }

  /**
   * Sends the current query as ajax call and displays retrieved data
   */
  sendRequest() {
    info(`Retrieving query results of "${this.query}"...`);

    const request = _.assign({}, REQUEST, {
      id: this.options.results,
      arguments: {
        q: this.query
      }
    });

    // increment active requests count
    this.activeRequests++;

    jQuery
      .ajax({
        type: "GET",
        url: this.$root.closest('form').prop('action'),
        dataType: "html",
        data: {
          eID: "solr",
          request: request
        },
        error: () => error("Unable to retrieve search-results!")
      })
      .always(this.onAfterRequest.bind(this, this.query));
  }

  onSubmit(ev) {
    if (this.options.mode === MODE.EXPANDING) {
      if (!this.isExpanded) {
        this.isExpanded = true;
        this.$root.find("input:first").focus();
        ev.preventDefault();
      }
    }
  }

  onClear(ev) {
    this.query = " ";
    this.query = "";

    if (this.options.mode === MODE.EXPANDING) {
      this.isExpanded = false;
    }

    ev.preventDefault();
    return false;
  }

  onAfterRequest(givenQuery, data, status, ev) {
    // decrement active requests count
    this.activeRequests--;

    if (status === "success" && this.queryContent.length) {
      this.ajaxResult = data;
    } else {
      this.ajaxResult = null;
    }

    this.queryOfResult = givenQuery;
  }

  handleKeypress(ev) {
    //this.query = ev.target.value

    if ([kc.DOWN, kc.UP].indexOf(ev.keyCode) >= 0) {
      ev.preventDefault();
    }

    switch (ev.keyCode) {
      case kc.DOWN:
        this.focusPreviewEntry(focus.NEXT);
        break;
      case kc.UP:
        this.focusPreviewEntry(focus.PREVIOUS);
        break;
      case kc.ESC:
        this.unfocus();
        break;
      case kc.NUMPAD_ENTER:
      case kc.ENTER:
        console.log("ENTER PERESSED");
        let $current = this.$results.data(activeEntry);
        if ($current && $current.length) {
          ev.preventDefault();

          var targetLink = null;

          if (!$current.is(href)) {
            targetLink = $current.find(href).first();
          }

          try {
            targetLink = targetLink.attr("href")
              ? targetLink.attr("href")
              : targetLink.attr("data-href");
          } catch (e) {
            targetLink = null;
          }

          if (!targetLink) {
            warn("Searchbox: Nothing to go to.");
          } else {
            window.location = targetLink;
          }

          //console.log(targetLink);
        }

        break;
    }
  }

  get $results() {
    return this.$root.find("[data-results]");
  }

  focusPreviewEntry(direction) {
    let method = direction === focus.PREVIOUS ? "prev" : "next";

    let $current = this.$results.data(activeEntry) || false;

    const panic = () =>
      this.$results.children(
        `:${direction === focus.PREVIOUS ? "last" : "first"}`
      );

    // isDefined? AND hasItems? AND inDocument?
    if (
      $current &&
      $current.length &&
      jQuery.contains(document.body, $current[0])
    ) {
      var $newCurrent = $current[method]();

      if ($newCurrent.length <= 0) $newCurrent = panic();

      $current = $newCurrent;
    } else {
      $current = panic();
    }

    this.$results
      .data(activeEntry, $current)
      .children()
      .removeClass(isFocused);
    $current.addClass(isFocused);
  }

  unfocus() {
    if (this.options.mode === MODE.EXPANDING) {
      this.isExpanded = false;
    }
  }

  set query(v) {
    this.queryContent = String(v).replace(/\s+/gi, " ");
    this.handleQueryChangeThrottled();
  }

  get query() {
    return this.queryContent;
  }
}

export const searchbox = makeComponent(AuwSolrSearch, "searchbox");
