import StackTrace from "stacktrace-js";
import { error } from "./console";

const CSS = {
  bold: [
    "background: #e74c3c",
    "padding: 3px 1px",
    "border-radius: 3px",
    "font-weight: bold",
    "color: #FFF"
  ].join(";"),
  boldReset: [
    "background: initial",
    "padding: initial",
    "border-radius: initial",
    "font-weight: initial",
    "color: inherit"
  ].join(";")
};

export function getFnName(fn) {
  var f = typeof fn === "function";
  var s =
    f &&
    ((fn.name && ["", fn.name]) || fn.toString().match(/function ([^(]+)/));
  return (!f && "not a function") || ((s && s[1]) || "anonymous");
}

export function getInstanceName(instance) {
  try {
    return Object.getPrototypeOf(instance).constructor.name;
  } catch (e) {
    return "anonymous";
  }
}

export function silentPrettyError(e) {
  const getFuncAndArgs = sf =>
    (sf.getFunctionName() || "{anonymous}") +
    ("(" + (sf.getArgs() || []).join(",") + ")");

  StackTrace.fromError(e, {}).then(stackframes => {
    let longest = 0;

    stackframes.map(sf => {
      let _b = getFuncAndArgs(sf);
      if (_b.length > longest) {
        longest = _b.length;
      }
    });

    var stringifiedStack = stackframes
      .map(function(sf, i) {
        var css = i < 1 ? "%c" : "";
        var fileName = sf.getFileName() ? " @ /" + sf.getFileName() : "";
        var lineNumber = _isNumber(sf.getLineNumber())
          ? ":" + sf.getLineNumber()
          : "";
        var columnNumber = _isNumber(sf.getColumnNumber())
          ? " " + sf.getColumnNumber()
          : "";
        return (
          css +
          getFuncAndArgs(sf) +
          fileName +
          lineNumber +
          columnNumber +
          css +
          (css ? "\n" : "")
        );
      })
      .join("\n\t");

    error("Error build component! More details below.");
    console.error(e.message + "\n" + stringifiedStack, CSS.bold, CSS.boldReset);
  });
}
export function _isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function getBaseClass(targetClass) {
  if (targetClass instanceof Function) {
    let baseClass = targetClass;

    while (baseClass) {
      const newBaseClass = Object.getPrototypeOf(baseClass);

      if (newBaseClass && newBaseClass !== Object && newBaseClass.name) {
        baseClass = newBaseClass;
      } else {
        break;
      }
    }

    return baseClass;
  }
}
