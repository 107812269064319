import { afterInit } from "old-framework";
import "bootstrap-filestyle";

const config = {
  buttonName: "btn-filestyle",
  iconName: "fa fa-folder-open",
  placeholder: "Keine Dateien",
  buttonText: "Datei auswählen"
};

function makeFileInputs() {
  $(":file").filestyle(config);
}

export const fileInput = afterInit(makeFileInputs);
