import { makeComponent, ComponentHelper } from 'old-framework';
import { forEach, findKey, assign } from 'lodash';

import 'slick-carousel';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI from 'photoswipe/dist/photoswipe-ui-default';
import PhotoSwipeBase from './templates/photoswipe.hbs';
import { UIConfig as photoswipeUIConfig } from './ui-config';

const HIDDEN_CLASS = 'gallery__figure--hidden';

const TYPES = {
  SINGLE: 3,
  THUMBS: 1,
  FULL: 2
};

const THUMBNAIL_CLASS = '.gallery__thumbnail';

const theatreConfig = {
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  cssEase: '.43,.01,0,.99',
  arrows: false,
  fade: true,
  lazyLoad: 'ondemand',
  adaptiveHeight: true
};

const thumbConfig = {
  speed: 300,
  centerMode: true,
  prevArrow: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
  nextArrow: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
  centerPadding: 0,
  slidesToShow: 3,
  slidesToScroll: 1
};

const photoswipeConfig = {
  showHideOpacity: true,
  bgOpacity: 0.75,
  errorMsg: `<div class="pswp__error-msg">Fehler beim Laden des <a href="%url%" target="_blank">Bildes</a>.</div>`,
  addCaptionHTMLFn: function(item, captionEl, isFake) {
    if (!item.title) {
      captionEl.children[0].innerText = '';
      return false;
    }
    captionEl.children[0].innerHTML =
      item.title + '<br/><small>Photo: ' + item.author + '</small>';
    return true;
  }
};

const VIEW = $(PhotoSwipeBase()).get(0);

/**
 * The most valuable image gallery ever build.
 * @author Maximiyarn dev
 * lian Scopp <me@maxscopp.de>
 */
export class AuwGallery extends ComponentHelper {
  constructor(node) {
    super(node);

    this.$root = $(node);
    this.mode = null;
    this.items = [];
    this.view = VIEW;

    this._defaults = {
      photoswipe: photoswipeConfig,
      showCarousel: false
    };
  }

  __AfterInit() {
    this.mode = this.options.mode;

    if (!this.mode || !findKey(TYPES, v => v === this.mode)) {
      throw new Error(
        [
          'Mode for AuwGallery not given or not valid, got',
          `[${typeof this.options.mode}] ${this.options.mode}`
        ].join()
      );
    }

    if (this.options.cId) this.options.photoswipe.galleryUID = this.options.cId;

    if (!jQuery.contains(document, this.view)) {
      $(this.view).appendTo(document.body);
    }

    this.nodes('item');
    this.node('theatre');
    this.node('thumbs');

    this.$theatre = $(this.nodeTheatre);
    this.$thumbs = $(this.nodeThumbs);
  }

  __PageReady() {
    this.collectImages();
    //console.log('Instance', this);

    this.$thumbs.find(`${THUMBNAIL_CLASS} > img`).each((iter, el) => {
      el.setAttribute('data-place', iter);
    });

    switch (this.mode) {
      case TYPES.FULL:
        this.buildFullMode();
        break;
      case TYPES.SINGLE:
        this.buildSingleMode();
        break;
      default:
        this.buildThumbsMode();
    }
  }

  /**
   * Helper. Merge Config using lodash's assign
   */
  _mc() {
    return assign({}, ...arguments);
  }

  /**
   * Retrieve all relevant data for the current instance.
   * Retrieval is based on the nodeItems variable.
   * @see this.nodeItems
   */
  collectImages() {
    /**
     * Retrieve image or entry data from an containing element
     * and push it to our items[] array to match the corresponding nodeItems.
     */
    function getData(element, index) {
      let $this = $(element);
      let $childs = $this.children();

      let $image = $childs.filter('img').first();

      let caption =
        $childs
          .filter('figcaption')
          .first()
          .html() || '';

      let alt = $image.prop('alt') || '';

      let entry = {
        place: index,
        w: $image.attr('data-w'),
        h: $image.attr('data-h')
      };

      $image.attr('data-place', index);

      let thumbSrc = $image.prop('src');
      if ($image.attr('data-small')) entry.msrc = $image.data('small');
      if ($image.attr('data-large')) entry.srcLarge = $image.data('large');

      if ($image && $image.length) {
        entry.src = $image.attr('data-original')
          ? $image.attr('data-original')
          : thumbSrc;
        entry.msrc = thumbSrc;
        entry.title = alt;
        entry.author = caption;
      } else {
        let $content = $childs.filter('[data-content]');
        if ($content && $content.length) {
          // get content; make hidden components initializable by auw-framework
          let dataContent = $content
            .parent()
            .html()
            .replace(/@manualComponent/gi, '@component');

          entry.html =
            `
            <div class="imageItem">

              <div class="imageItem__content">
                ${dataContent}

                ` +
            ((alt || caption).trim().length
              ? `<div class="imageItem__footer">
                  <span class="imageItem__copyright">${alt || ''}</span>
                  <figcaption>${caption || ''}</figcaption>
                </div>`
              : '') +
            `
              </div>

            </div>
            `;
        }
      }

      this.items.push(entry);
    }

    // apply function to each item; keep context!
    forEach(this.nodeItems, getData.bind(this));
  }

  buildFullMode() {
    this.$root.addClass('gallery--full');

    // on image click, get image index and call showGallery()
    this.$thumbs.on('click', 'img', ev => {
      let index = parseInt(
        (ev.target || ev.currentTarget).getAttribute('data-place'),
        10
      );
      this.$theatre.slick('getSlick').goTo(index, false);
    });

    this.$theatre.slick(
      this._mc(theatreConfig, {
        asNavFor: this.options.showCarousel ? this.$thumbs.get(0) : undefined
      })
    );

    this.$theatre.find('[\\@manualcomponent]').each((iter, el) => {
      let parent = el.parentNode;
      parent.innerHTML = parent.innerHTML.replace(
        /@manualComponent/gi,
        '@component'
      );
    });

    this.addFullscreenButton();

    if (this.options.showCarousel) {
      this.$thumbs.addClass('gallery__thumb--carousel').slick(
        this._mc(thumbConfig, {
          asNavFor: this.$theatre.get(0)
        })
      );
    } else {
      this.$theatre.show();
    }
  }

  buildThumbsMode() {
    this.$root.addClass('gallery--thumbs');
    this.$theatre.hide();

    const $thumbImages = this.$thumbs.find('img');

    // apply configuration if we should show just X item
    if (this.options.showMaxThumbs && this.options.showMaxThumbs > 0) {
      let max = Number(this.options.showMaxThumbs);
      this.$thumbs
        .children()
        .filter((i, el) => i > max)
        .hide();
    }

    // apply listeners to show gallery
    this.$thumbs.on('click', 'img', ev => {
      let clickedEl = ev.target || ev.currentTarget;
      let index = $thumbImages.index(clickedEl);
      this.showGallery(index);
    });
  }

  buildSingleMode() {
    this.$root.addClass('gallery--single');
    this.$thumbs.hide();
    this.$theatre.show();

    for (let item of this.nodeItems) {
      $(item).removeClass(HIDDEN_CLASS);
    }

    this.addFullscreenButton();
  }

  addFullscreenButton() {
    this.$theatre.prepend(
      `<button show-image class="gallery__btn-fullscreen"><i class="fa fa-expand" aria-hidden="true"></i></button>`
    );

    // on image click, get image index and call showGallery()
    this.$theatre.on('click', '[show-image]', () => {
      let index = null;
      try {
        index = this.$theatre.slick('slickCurrentSlide');
      } catch (e) {
        console.info(
          'Presumably working in single mode, ignoring index retrieval by Slick.'
        );
      }

      this.showGallery(index || 0);
    });
  }

  showGallery(index) {
    if (this.instance) {
      try {
        this.instance.close(); // after that, destroy is called - by magic!
      } catch (e) {}
    }

    this.instance = new PhotoSwipe(
      this.view,
      PhotoSwipeUI,
      this.items,
      assign({}, this.options.photoswipe, photoswipeUIConfig)
    );

    if (index) {
      if (typeof index !== 'number') {
        throw new TypeError('Index is required to be an integer.');
      }

      this.instance.options.index = index;
    }

    this.instance.options.getThumbBoundsFn = this.getThumbnailBoundsByIndex.bind(
      this
    );

    this.instance.init();
  }

  getThumbnailBoundsByIndex(index) {
    if (typeof index !== 'number' && index >= 0) {
      throw new TypeError(
        `Index is required and being an integer, saw ${typeof index} ${index}`
      );
    }

    // find thumbnail element
    var thumbnail = this.$thumbs
      .find(`${THUMBNAIL_CLASS} > img`)
      .eq(index)
      .get(0);

    if (thumbnail) {
      // get window scroll Y
      var pageYScroll =
        window.pageYOffset || document.documentElement.scrollTop;
      // optionally get horizontal scroll

      // get position of element relative to viewport
      var rect = thumbnail.getBoundingClientRect();

      // w = width
      return {
        x: rect.left,
        y: rect.top + pageYScroll,
        w: rect.width
      };

      // Good guide on how to get element coordinates:
      // http://javascript.info/tutorial/coordinates
    } else {
      //console.log('No thumb :(', this.$thumbs, index);

      return {};
    }
  }
}

forEach(TYPES, (element, index) => {
  AuwGallery[`${index}`] = element;
});

window['AuwGallery'] = AuwGallery;
export const component = makeComponent(AuwGallery, 'gallery');
