import { makeComponent, ComponentHelper, info } from "old-framework";
import * as BackgroundCheck from "background-check/background-check.min";
import "slick-carousel";

const dotsClass = "slider__dots";

class AuwSlider extends ComponentHelper {
  constructor(node) {
    super(node);

    this._defaults = {
      dotsClass: dotsClass,
      nextArrow: '<span class="slider__arrow slider__arrow--next"></span>',
      prevArrow: '<span class="slider__arrow slider__arrow--prev"></span>',

      pauseOnHover: true,
      pauseOnDotsHover: true,
      mobileFirst: true,
      cssEase: "cubic-bezier(.4,0,.2,1)",
      accessibility: true,
      infinite: false,
      dots: false,
      speed: 800,
      slide: '.slide',
      rows: 0
    };

    this.$slider = $(node);

    this.instance = null;
    this.backgroundCheckInstance = null;
  }

  __AfterInit() {
    this.$slider.on("afterChange beforeChange setPosition", () => {
      try {
        BackgroundCheck.refresh();
      } catch (e) {
        console.warn(e);
      }
    });
  }

  __PageReady() {
    this.$slider.children().show();
    this.instance = this.$slider.slick(this.options);
    this.backgroundCheckInstance = BackgroundCheck.init({
      targets: `.slider__arrow,.${dotsClass}`,
      images: ".slider__slide img",
      classes: {
        dark: "slider--light",
        light: "slider--dark",
        complex: "slider--complex"
      }
    });
    info("Initialized Slider.");
  }
}

export default makeComponent(AuwSlider, "slider");
